<script setup lang="ts">

import { Category } from "@shopware-pwa/types";
import { useCategorySearch } from "@shopware-pwa/composables-next";
import { Ref } from "vue";
import { getCategoryBreadcrumbs } from "@shopware-pwa/helpers-next";
import { useCmsHead } from "@/composables/useCmsHead";
import {
  UseNavigationReturn,
} from "@shopware-pwa/composables-next";
import {hash} from "ohash";
import * as Sentry from "@sentry/vue";

const props = defineProps<{
  navigationId: string;
}>();

const mainNavigation = inject(
  "swNavigation-main-navigation"
) as ComputedRef<UseNavigationReturn>;

const { search } = useCategorySearch();
const route = useRoute();

// has to contain navigationId and all search() relevant params in line 41ff
const categoryHashKey = hash({
  navigationId: props.navigationId,
  query: {
    ...route.query,
  }
})

const { data: categoryResponse } = await useAsyncData(
  `data-categorypage:${categoryHashKey}`,
  async () => {
    const { value, addToCache } = await useDataCache(`data-categorypage:${categoryHashKey}`)

    if (value) {
      return value
    }

    const category = await search(props.navigationId, {
      withCmsAssociations: true,
      query: {
        ...route.query,
      },
    });

    addToCache(category, getCategoryCacheKeys(category), 3600);

    return category;
  },
);

if (categoryResponse?.value && typeof categoryResponse?.value !== null) {
  const breadcrumbs = getCategoryBreadcrumbs(categoryResponse.value, {
    startIndex: 1,
  });
  useBreadcrumbsMainNavigation(mainNavigation, breadcrumbs);
} else {
  if (process.client) {
    Sentry.captureMessage(`Category for route ${route.fullPath} having navigationId ${props.navigationId} not found`)
  }
}

const { category } = useCategory(categoryResponse as Ref<Category>);
useCmsHead(category, { mainShopTitle: "Maas Natur" });
</script>

<template>
  <LayoutBreadcrumbs />
  <CmsPage v-if="category?.cmsPage" :content="category.cmsPage" />
</template>
